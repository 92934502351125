import React from 'react';
import { Helmet } from "react-helmet";
import Cookies from "../components/cookies";
import LayoutDefault from "../container/LayoutDefault";
import Header from "../components/header/Header";
import HeaderPage from "../components/header/HeaderPage";
import ProjectsShowcase from "../container/ProjectsShowcase";
import Contact from "../container/Contact";
import Footer from "../container/Footer";

const Projects = () => {

    return (
        <>
            <Helmet>
                <title>TheBrandBar | Projects</title>
            </Helmet>
            <Cookies /> 
            <LayoutDefault className="template-color-2 bg_color--4">
                <Header />
                <HeaderPage
                    title="VISUAL COCKTAILS"
                />
                <ProjectsShowcase/>
                <Contact />
                <Footer />
            </LayoutDefault>
        </>
    );
};

export default Projects;
