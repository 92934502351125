import React from 'react';
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import logo from '../assets/img/logo/brandbar-black.png';
import icecubebg from '../assets/img/shapes/icecubebg.svg';
import icecube from '../assets/img/gifs/icecube.gif';


const FooterTwo = () => {
    return (
        <footer className="footer bg_color--1 pl--150 pr--150 pl_lp--30 pr_lp--30">
            <div className="bk-footer-inner pt--150 pt_sm--100">
                <Row>

                    <div className="logo">
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <div className="iceday">
                        <img className="ice-gif" src={icecube} alt="" />
                        <img className="ice-bg" src={icecubebg} alt="" />
                    </div>

                    <div className="footer-menu">
                        <Row className="align-items-center">
                            <div className=" text-md-right text-center">
                                <ul className="bk-hover flex-wrap ">
                                    <li><Link to={process.env.PUBLIC_URL + "/terms"}>Terms of Use</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/cookies"}>Cookies</Link></li>
                                </ul>
                            </div>
                        </Row>
                    </div>

                </Row>
            </div>


        </footer>
    );
};

export default FooterTwo;