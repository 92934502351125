import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';


const PortfolioItem = ({ index, title, tags, thumb}) => {
    return (
        <div className={`portfolio portfolio-25`}>
            <div className="poss_relative">
                <Link to={`${process.env.PUBLIC_URL}/client/${index}`} className="tooltip-link">
                <div className="thumb">
                    <img src={thumb} alt={title}/>
                </div>
                <span className="tooltip-description text"><h3>{title}</h3><p>{tags}</p></span>
                </Link>

            </div>
        </div>
    );
};

PortfolioItem.propTypes = {
    className: Proptypes.string
}

export default PortfolioItem;
