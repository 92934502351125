import React from 'react';
import { Link } from 'react-scroll';
import MobileMenuItem from "./MobileMenuItem";


const PopupMobileMenu = () => {

    const closeMobileMenu = () => {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.remove('popup-mobile-menu-wrapper');
        html.style.overflow = "auto"
    };


    return (
        <div className="popup-mobile-menu popup-mobile-visible">
            <div className="overlay" onClick={() => { closeMobileMenu() }} />
            <div className="inner">
                <div className="mobileheader">
                    <button className="mobile-close" onClick={() => { closeMobileMenu() }} />
                </div>
                <div className="menu-content">
                    <ul className="menulist object-custom-menu">
                        <MobileMenuItem />
                        <li>
                            <Link to="contact" smooth={true} duration={500}  onClick={() => { closeMobileMenu() }} ><span>Say Hi!</span></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PopupMobileMenu;