import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const HeaderPage = ({className, title}) => {
    return (
        <div className={`breadcrumb-area breadcrumb-title-bar pt--200 pt_sm--150 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="breadcrumb-inner">
                            <h2 className="">{title}</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HeaderPage;
