import React from "react";
// import imgLoader from "../../assets/images/loader.svg";
import Wave from 'react-wavify'


const Loader = ({ children }) => {
    // setTimeout(function() {
    //   document.getElementById("loader-overlay").style.display = "none"
    // }, 2000)

  return (
    <>
      <div id="loader-overlay">

      <div className="wave-loader">
                <Wave
                    className={'wavify-item'}
                    fill='#000'
                    paused={false}
                    options={{
                        height: 20,
                        amplitude: 20,
                        points: 4,
                        speed: 1
                    }}
                />
            </div>

      </div>
      {children}
    </>
  );
};

export default Loader;
