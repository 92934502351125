import React from 'react';
import PageContainer from "./PageContainer";
import PopupMobileMenu from "../components/mobile-menu/MobileMenu";

const LayoutDefault = ({children, className, revealFooter}) => {
    return (
        <PageContainer className={className} revealFooter={revealFooter}>
            <PopupMobileMenu/>
            {children}
        </PageContainer>
    );
};

export default LayoutDefault;