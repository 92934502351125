import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from "../container/Loader";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import Cookies from "../components/cookies";
import LayoutDefault from "../container/LayoutDefault";
import HeaderProject from "../components/header/HeaderProject";
import SliderSwiper from "../components/swiper";

const ProjectPage = ({ match }) => {
    const { params: { clientId } } = match;

    const [projects, setProjects] = useState(null)
    const [swiper, updateSwiper] = useState(null);
    const settings = {
        slidesPerView: "auto",
        spaceBetween: 0,
        autoplay: true,
        mousewheel: true,
        loop: false,
        freeMode: true,
    };
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    useEffect(() => {
        var isCancelled = false;
        axios.get("./projects.json").then(
            (response) => !isCancelled ? setProjects(response.data) : console.log("is canceled")
        ).catch(
            (err) => console.log(err)
        )
    }, [])

    const goToPreviousPage = () => {
        if (window.history.length === 1) {
            window.location = "/"
        } else {
            window.history.back();
        }
    }

    return (
        <Loader>
            <Helmet>
                <title>TheBrandBar | {projects ? projects[clientId - 1].title : ""}</title>
            </Helmet>
            <Cookies />
            <LayoutDefault className="template-color-2 bg_color--4 project-page">
                <button onClick={goToPreviousPage} className="page-close" />
                <Row>
                    <Col xs={12} lg={4}>
                        <HeaderProject
                            title={projects ? projects[clientId - 1].title : ""}
                            description={projects ? projects[clientId - 1].description : ""}
                        />
                        <div onClick={goNext} className="scroll-btn mt--25">
                            <span>SCROLL TO SEE MORE</span>
                            <span className="btn-arrow" />
                        </div>
                    </Col>
                    <Col xs={12} lg={8}>
                        {projects ?
                            <SliderSwiper
                                reference={updateSwiper}
                                settings={settings}
                            >
                                {
                                    projects ? projects[clientId - 1].images.map((image) => (
                                        <div key={image} className="image-slide mt--15">
                                            <img src={`${process.env.PUBLIC_URL}/projects/${clientId}/${image}`} alt="" />
                                        </div>
                                    )) : ""
                                }
                            </SliderSwiper> : console.log("projects loading")}
                    </Col>
                </Row>
            </LayoutDefault>
        </Loader>
    );
};

export default ProjectPage;