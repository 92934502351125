import React from 'react';
import { Col, Container, Row } from "react-bootstrap";


const AboutText = () => {
    return (
            <Container>
                <Row>
                    <Col className="text-about">
                        <div className="info">
                            <p>Think of us as that chill & cool bartender who listens to your business needs and creates a strategy for the best cocktail that's going to rock the market.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
};

export default AboutText;
