import React from 'react';

const SocialIconItem = ({socialMediaIcon, socialMediaLink, className, type}) => {

    return (
        <li className={socialMediaIcon}>
            <a
                href={`${socialMediaLink}`}
                rel="noopener noreferrer"
                className={className}
                aria-label={socialMediaIcon}
                target="_blank"
            >
                {type === 'icon' && <i className={`fa fa-${socialMediaIcon}`}/>}
                {type === 'text' && <span style={{textTransform: 'capitalize'}}>{socialMediaIcon}</span>}
            </a>
        </li>
    );
};

export default SocialIconItem;
