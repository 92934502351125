import React from 'react';
import { Col } from "react-bootstrap";

const HeaderProject = ({ className, title, description }) => {
    return (
        <div className={`project-header ${className}`}>
            <Col>
                    <h2 className="project-title">{title}</h2>
                    <p className="project-description">{description}</p>
            </Col>
        </div>
    );
};

export default HeaderProject;
