import React from 'react';
import {Helmet} from "react-helmet";
import Cookies from "../components/cookies";
import LayoutDefault from "../container/LayoutDefault";
import Header from "../components/header/Header";
import HeaderPage from "../components/header/HeaderPage";
import AboutText from "../components/about/About";
import TeamMembers from "../components/about/TeamMembers";
import Contact from "../container/Contact";
import Footer from "../container/Footer";


const TeamGrid = () => {
    return (
        <>
            <Helmet>
                <title>TheBrandBar | Team</title>
            </Helmet>
            <Cookies/>
            <LayoutDefault className="template-color-2 bg_color--4">
                <Header/>
                <HeaderPage
                    title="WE ARE A CREATIVE DESIGN STUDIO BASED IN CLUJ-NAPOCA."
                />
                <AboutText />       
                <TeamMembers />
                <Contact />
                <Footer />
            </LayoutDefault>
        </>
    );
};

export default TeamGrid;
