let tracksList = [
  {
    title: "OLD FASHIONED",
    id: 0,
  },
  {
    title: "I'M STILL SINGLE-MALT",
    id: 1,
  },
  {
    title: "NO QUIREO TRABAJAR TEQUILA",
    id: 2,
  },
  {
    title: "BITCH ‘N' TONIC",
    id: 3,
  },
  {
    title: "EXISTENTIAL DAIQUIRI",
    id: 4,
  },
  {
    title: "GOOD MOOD ON THE ROCKS",
    id: 5,
  },
];

export default tracksList;


