import React, { useState } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helper/scrollToTop";

import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Team from "./pages/Team";
import ProjectPage from "./pages/ProjectPage";
import Error from "./pages/Error";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Cookies from "./pages/Cookies";


const App = () => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
    const [trackURL, setTrackURL] = useState('');
    const [playing, setPlaying] = useState(false);

    const callbackFunction = (currentTrackIndex, trackURL, playing) => {
        setCurrentTrackIndex(currentTrackIndex);
        setTrackURL(trackURL);
        setPlaying(playing);
    };

    const player = document.getElementById('player');
    const onPlayPauseClickHandler = (event) => {
        setPlaying(!playing);
            !playing ? player.play() : player.pause();
    }
    
    const renderPlayBtn = () => {
        if (currentTrackIndex == null) {
            return;
        } else {
            return <div className="play-btn">
                <p onClick={onPlayPauseClickHandler} className={`play ${playing ? "red-bg" : "blue-bg"}`}>
                    {playing ? "SOUND OFF" : "SOUND ON"}
                </p>
            </div>
        }
    }

    return (
        <>
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL + '/'}`} >
                        <Home parentCallback={callbackFunction} player={player} musicCover={playing}/>
                    </Route>
                    <Route
                        path={`${process.env.PUBLIC_URL + '/clients'}`}
                        component={Projects}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL}/client/:clientId`}
                        component={ProjectPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/team'}`}
                        component={Team}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/terms'}`}
                        component={TermsOfUse}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/privacy'}`}
                        component={PrivacyPolicy}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/cookies'}`}
                        component={Cookies}
                    />
                    <Route exact component={Error} />
                </Switch>
            </Router>
            <audio id="player" src={trackURL} type="audio/mpeg"></audio>
            {
                renderPlayBtn()
            }
        </>
    );
};

export default App;