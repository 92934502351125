import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Container, Row } from "react-bootstrap";
import socialIcons from '../data/socials';
import Input from "../components/input";
import Wave from 'react-wavify'
import SocialIconItem from "../components/social-icon";


const Contact = () => {

    const [contactFeedback, setContactFeedback] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [validateRequest, setValidateRequest] = useState(false);

    useEffect(() => {

        if (validateRequest === true) {
            setValidateRequest(false);
            if (contactName.length === 0) {
                setContactFeedback("Please complete all fields!");
                return;
            } else if (!contactName.match(/^[A-Za-z]+$/)) {
                setContactFeedback("Name should contain only letters!");
                return;
            }

            if (contactEmail.length === 0 || !contactEmail.match(/^[A-Za-z._\-[0-9]*[@][A-Za-z]*[.][a-z]{2,4}$/)) {
                setContactFeedback("Invalid Email!");
                return
            }

            if (contactMessage.length === 0) {
                setContactFeedback("Please complete all fields!");
                return
            }

            axios.post("./contact.php", JSON.stringify({
                name: contactName,
                email: contactEmail,
                message: contactMessage,
            }));

            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
            document.getElementById("message").value = "";
            setContactFeedback("CHEERS!");

        }
    }, [validateRequest, contactName, contactEmail, contactMessage]);


    const contactForm = () => {
        setContactName(document.getElementById('name').value);
        setContactEmail(document.getElementById('email').value);
        setContactMessage(document.getElementById('message').value);
        setValidateRequest(true);
    }


    return (
        <>
            <div className="wavify-wrapper mt--50">
                <Wave
                    className={'wavify-item'}
                    fill='#EA6650'
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: 70,
                        points: 4
                    }}
                />
            </div>
            <div id="contact" className="poss_relative">

                <div className="text-crawler mb--50">
                    <h1>WHERE BRANDS COME TO GET LUCKY | WHERE BRANDS COME TO GET LUCKY</h1>
                </div>

                <Container>
                    <Row>
                        <Col xs={12} lg={7}>
                            <div className="mb--20">
                                <h2>LET'S GET TIPSY ON VISUAL COCKTAILS AND DESIGN SHOTS</h2>
                            </div>
                            <ul className="social-icon icon-rounded style-solid-thin-rounded-icon text-left">
                                {socialIcons.map(icon => (
                                    <SocialIconItem
                                        key={icon.id}
                                        type={'icon'}
                                        socialMediaIcon={icon.media}
                                        socialMediaLink={icon.link}
                                        className={"link"}
                                    />
                                ))}
                            </ul>
                        </Col>

                        <Col xs={12} lg={5} className="mt_md--40 mt_sm--40">
                            <div className="contact-form" id="contact-form">
                                <form method="POST" className="form-style">
                                    <div className="row">
                                        <Col lg={12}>
                                            <div className="input-box">
                                                <Input
                                                    id="name"
                                                    type={"text"}
                                                    name={"name"}
                                                    placeholder={"Name"}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <div className="input-box">
                                                <Input
                                                    id="email"
                                                    type={"email"}
                                                    name={"email"}
                                                    placeholder={"Email"}
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={12}>
                                            <div className="input-box">
                                                <Input
                                                    id="message"
                                                    type={"text"}
                                                    name={"message"}
                                                    placeholder={"Your message"}
                                                />
                                            </div>
                                        </Col>

                                        <Col xs={12}>
                                            <div className="form-action">
                                                <div className="form-submit" onClick={contactForm}>
                                                    <div>SEND</div>
                                                </div>
                                                <div className="form-feedback">
                                                    {contactFeedback}
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
}

export default Contact;
