import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SliderSwiper from "../components/swiper";



const FeaturedProjects = () => {
    const [swiper, updateSwiper] = useState(null);
    const [projects, setProjects] = useState(null)
    const settings = {
        slidesPerView: "auto",
        spaceBetween: 0,
        autoplay: false,
        mousewheel: true,
        loop: false,
        freeMode: true,
    };
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };


    useEffect(() => {
        var isCancelled = false;
        axios.get("./projects.json").then(
            (response) => !isCancelled ? setProjects(response.data) : console.log("is canceled")
        ).catch(
            (err) => console.log(err)
        )
        return () => {
            isCancelled = true;
        };
    }, [])


    return (
        <div className="featured-projects align-items-center mt--100">
            <Container>
                <h2>Our Clients</h2>
            </Container>
            <Col xs={12} lg={12} className="mt--50 mb--100">
                {projects ?
                    <SliderSwiper reference={updateSwiper} settings={settings}>
                        {
                            projects ? projects.map((project, index) => (
                                <div key={index} className="project-slide mt--15">
                                    <Link key={index} to={`${process.env.PUBLIC_URL}/client/${projects[index].id}`} className="tooltip-link">
                                        <img src={`${process.env.PUBLIC_URL}/projects/${projects[index].id}/cover.jpg`} alt="" />
                                        <p className='project-title'>{project.title}</p>
                                        <p>{project.words}</p>
                                    </Link>
                                </div>
                            )).reverse().slice(0, 6) : ""
                        }
                    </SliderSwiper> : console.log("projects loading")}
                <div onClick={goNext} className="next-btn">
                    <span className="btn-arrow" />
                </div>
                <div onClick={goPrev} className="prev-btn">
                    <span className="btn-arrow" />
                </div>
            </Col>
            <Container>
                <Link to="/clients" >
                    <div className="see-more-btn">
                        <div>SEE MORE</div>
                    </div>
                </Link>
            </Container>
        </div>

    );
};

export default FeaturedProjects;
