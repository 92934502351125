import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import DB from '../data/music/music.js';


const Player = ({ parentCallback, musicCover }) => {

  const [tracksList] = useState(DB);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [trackID, setTrackID] = useState(null);
  const [trackURL, setTrackURL] = useState('');
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    parentCallback(currentTrackIndex, trackURL, playing);
  }, [currentTrackIndex, trackURL, playing]);

  const selectThisTrack = (event, trackID) => {
    let currentTrackIndex = 0;
    tracksList.map((track, index) => {
      if (track.id === trackID) {
        currentTrackIndex = index;
      }
      return track;
    });
    setCurrentTrackIndex(currentTrackIndex);
    setTrackID(tracksList[currentTrackIndex].id);
    setTrackURL(require(`../assets/player/music/${currentTrackIndex + 1}.mp3`));
    setPlaying(true);
    const player = document.getElementById('player');
    player.src = trackURL;
    player.preload = true;
    player.autoplay = true;
    player.loop = true;
    !playing ? player.play() : player.pause();
  }
  

  const renderCover = () => {
    if (playing === false || musicCover === false) {
      return <img className="img-cover-default" src={require(`../assets/player/covers/default.gif`)} alt="" />
    } else {
      return <img className="img-cover" src={require(`../assets/player/covers/${currentTrackIndex + 1}.gif`)} alt="" />
    }
  }


  return (
    <>
      <Container>
        <Row>
          <Col className="play-list" xs={{ span: 12, order: 2 }} lg={{ span: 4, order: 1 }}>
            <p className="your-rythm ">WHAT'S YOUR RHYTHM?</p>
            <br />
            {DB.map((track, index) => (
              <div
                key={track.id}
                index={index}
                className={`song ${track.id === trackID ? "selected" : ""}`}
                id={track.id === trackID ? "activeTrack" : ""}
                onClick={(ev) => selectThisTrack(ev, track.id)}
              >
                <p>{track.title}</p>
              </div>
            ))}
          </Col>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 8, order: 2 }}>

            <div className="song-cover">
              {
                renderCover()
              }
            </div>

          </Col>
        </Row>
      </Container>

    </>
  );
};

export default Player;