import React from 'react';
import HeaderContainer from "../../container/HeaderContainer";
import Logo from "./elements/logo";
import Navbar from "./elements/navbar/Navbar";
import HeaderConfig from "./elements/config";
import logo from '../../assets/img/logo/brandbar-whtie.png'

const Header = () => {
    return (
        <HeaderContainer
            transparent={true}
            sticky={false}
        >
            <div className="header-left flex-20">
                <Logo logo={logo}/>
            </div>

            <div className="header-flex-right flex-80">
                <Navbar/>
                <HeaderConfig/>
            </div>
        </HeaderContainer>
    );
};

export default Header;