import React from 'react';
import { Container } from "react-bootstrap";
import "../helper/tooltip";


import bunny from '../assets/img/gifs/bunny.gif'
import we from '../assets/img/gifs/we.gif'
import mix from '../assets/img/gifs/mix.gif'
import magic from '../assets/img/gifs/magic.gif'
import skills from '../assets/img/gifs/skills.gif'
import creativity from '../assets/img/gifs/creativity.gif'
import recipe from '../assets/img/gifs/recipe.gif'
import brand from '../assets/img/gifs/brand.gif'

const Hero = () => {

    return (
        <div className="hero-area">

            <Container>
                <div className="position-relative">
                    <div className="hero-content pb--50  pt--200 pt_sm--150">
                        <div className="hero-title">
                            <h2>
                                <span className="tooltip-link">WE <span className="tooltip-description"><img src={we} alt="" /></span></span> 
                                <span className="tooltip-link">MIX <span className="tooltip-description"><img src={mix} alt="" /></span></span>
                                <span className="tooltip-link">MAGIC<span className="tooltip-description"><img src={magic} alt="" /></span></span>
                                , 
                                <span className="tooltip-link"> SKILLS <span className="tooltip-description"><img src={skills} alt="" /></span></span>
                                AND
                                <span className="tooltip-link"> CREATIVITY <span className="tooltip-description"><img src={creativity} alt="" /></span></span>
                                TO GET THE BEST
                                <span className="tooltip-link"> RECIPE <span className="tooltip-description"><img src={recipe} alt="" /></span></span>
                                <span className="tooltip-link"> FOR YOUR BRAND <span className="tooltip-description"><img src={brand} alt="" /></span></span>
                                .
                            </h2>
                        </div>
                        <p>
                            Our clients are as different as whiskey and wine, as lemon and cinnamon, as ice and no ice.
                        </p>
                    </div>
                </div>
            </Container>

            <div className="mt--100 mb--25 mb_sm--50 text-crawler light-text">
                <h1>WHAT'S YOUR RHYTHM?<img src={bunny} alt="" />WHAT'S YOUR RHYTHM?<img src={bunny} alt="" />WHAT'S YOUR RHYTHM?<img src={bunny} alt="" />WHAT'S YOUR RHYTHM?<img src={bunny} alt="" />WHAT'S YOUR RHYTHM?<img src={bunny} alt="" />WHAT'S YOUR RHYTHM?</h1>
            </div>

        </div>
    );
};

export default Hero;
