import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const Error404 = () => {
    return (
        <div className="error-not-found">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="error-inner text-center">
                            <h3 className="heading heading-h3 text-white">Looks like you are lost.</h3>
                            <div className="error-text mt--20">
                                <p className="text-white">
                                    It looks like nothing was found at this location. You can either go back to the last page or go to homepage.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Error404;
