import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const Cookies = () => {
    const cookieStorage = {
        getItem: (item) => {
            const cookies = document.cookie
                .split(';')
                .map(cookie => cookie.split('='))
                .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
            return cookies[item];
        },
        setItem: (item, value) => {
            document.cookie = `${item}=${value};`
        }
    }

    const storageType = cookieStorage;
    const consentPropertyName = 'cookie_consent_TheBrandBar';
    const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
    const saveToStorage = () => storageType.setItem(consentPropertyName, true);


    useEffect(() => {
        const consentPopup = document.getElementById('cookie-consent');
        const acceptBtn = document.getElementById('accept');

        const acceptFn = event => {
            saveToStorage(storageType);
            consentPopup.classList.add('hidden');
        }
        acceptBtn.addEventListener('click', acceptFn);

        if (shouldShowPopup(storageType)) {
            consentPopup.classList.remove('hidden');
        }
      });



    return (

        <div id="cookie-consent" className="hidden">
            <div className="cookies-popup">
                <div className="cookies-content">
                    <h3>
                        WARNING!<br />WE <span>ARE</span> TOUGH<br />COOKIES
                </h3>
                    <div className="cookies-options">
                        <Link className="square-btn" id="accept" to="#">ACCEPT COOKIES</Link>
                        <Link className="more-cookies" to="Cookies">LEARN MORE</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cookies;
