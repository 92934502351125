import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Cookies from "../components/cookies";
import LayoutDefault from "../container/LayoutDefault";

const PrivacyPolicy = () => {
    const goToPreviousPage = () => {
        if (window.history.length === 1) {
            window.location = "/"
        } else {
            window.history.back();
        }
    }

    return (
        <>
            <Helmet>
                <title>TheBrandBar | Privacy Policy</title>
            </Helmet>
            <Cookies />
            <LayoutDefault className="template-color-2 bg_color--4">
                <button onClick={goToPreviousPage} className="page-close" />
                <Container>
                    <Row>
                        <Col className="tpc-text">
                            <br /><br />
                            <h2>PRIVACY POLICY</h2>
                            <br /><br />
                            <p>
                                The personal data we save is the data you provide us by filling
                                in the contact form that we have included in the pages of the website.
                                <br /><br />
                                Our website does not use third party partners, so the data provided
                                by you will not be shared with other people outside the organization.
                                <br /><br />
                                <span>1. What data do we collect?</span>
                                <br />
                                Our Company collects the following data:
                                Personal identification information (Name, email address.)
                                and any other information you as an user write in the existing
                                fields on the contact page of the website.
                                <br /><br />
                                <span>2. Your rights</span>
                                <br />
                                the right to confirm whether or not your data is processed
                                to request a copy of the personal data we have saved (where applicable)
                                the right to request that your data be deleted (this cannot be applied in situations where the collaboration between you and our company has not been completed)
                                to request that your data be updated if they are incorrect
                                <br /><br />
                                <span>3. Links to other websites</span>
                                <br />
                                Our website may contain a number of external links to other sites.
                                The Brand Bar is not responsible for the content and functionalities
                                existing on other sites.
                                <br /><br />
                                <span>4. Changing the privacy policy</span>
                                <br />
                                The Brand Bar reserves the right to change and update at any
                                time the content of this page, but also of the current website,
                                without any prior notice. If you have any questions about the
                                privacy policy and how your data is used, please contact us at
                                the following email address: cheers@thebrandbar.studio .
                                <br /><br />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </LayoutDefault>
        </>
    );
};

export default PrivacyPolicy;
