import React from 'react';
import {Link} from "react-router-dom";

const closeMobileMenu = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    body.classList.remove('popup-mobile-menu-wrapper');
    html.style.overflow = "auto"
};

const Logo = ({logo}) => {
    return (
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}  onClick={() => { closeMobileMenu() }} >
                < img src={logo} alt="logo"/>
            </Link>
        </div>
    );
};

export default Logo;