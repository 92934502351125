import React from 'react';
import { Link } from 'react-scroll';
import NavbarItem from './NavbarItem';

const Navbar = ({ className }) => {
    return (
        <div className={`mainmenu-wrapper d-none d-lg-block ${className ? className : ''}`}>
            <nav className="page_nav">
                <ul className="mainmenu">
                    <NavbarItem />
                    <li className="label-1">
                        <Link to="contact" smooth={true} duration={500}><span>Say Hi!</span></Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;