import React, { useState, useEffect,  } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import logo from '../assets/img/logo/brandbar-black.png';
import icecubebg from '../assets/img/shapes/icecubebg.svg';
import icecube from '../assets/img/gifs/icecube.gif';


const Services = () => {



    useEffect(() => {
        const items = document.querySelectorAll(".accordion button");
        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');

            for (var i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }
            if (itemToggle == 'false') {
                this.setAttribute('aria-expanded', 'true');
            }
        }
        items.forEach(item => item.addEventListener('click', toggleAccordion));      
    }, [])
    


    return (
        <Container>
            <div className="accordion mt--150">
                <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                        <h2>BRANDING</h2><span className="icon" aria-hidden="true"></span>
                        <p>branding . rebranding . brand stabilisation</p>
                    </button>
                    <div className="accordion-content">
                        <p>We construct your brand in a meaningful way to match your story, delivering brand identities with a twist.
                            <br />Not just a logo, we create stories through fonts, colours, adding a whole new flavour of visual fireworks as your own signature visual cocktail.
                        </p>
                    </div>
                </div>
                <div className="accordion-item">
                    <button id="accordion-button-2" aria-expanded="false">
                        <h2>WEBSITE . SOCIAL MEDIA</h2><span className="icon" aria-hidden="true"></span>
                        <p>visual consistency across platforms</p>
                    </button>
                    <div className="accordion-content">
                        <p>We create aesthetic websites with a unique experience and help your brand with a bold visual consistency across different social media platforms.</p>
                    </div>
                </div>
                <div className="accordion-item">
                    <button id="accordion-button-3" aria-expanded="false">
                        <h2>CONSULTANCY & BRAND AUDIT</h2><span className="icon" aria-hidden="true"></span>
                    </button>
                    <div className="accordion-content">
                        <p>We're here for you every step of the way. From analysing your current brand, offering recommendations and insights to delivering fresh and effective ideas to make your brand more distinctive, memorable and authentic.</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Services;