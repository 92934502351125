import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PortfolioItem from "../components/portfolio";
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";

const ProjectsShowcase = () => {
    const [projects, setProjects] = useState(null)

    useEffect(() => {
        var isCancelled = false;
        axios.get("./projects.json").then(
            (response) => !isCancelled ? setProjects(response.data) : console.log("is canceled")
        ).catch(
            (err) => console.log(err)
        )
        return () => {
            isCancelled = true;
          };
    })


        const portfolioItems = document.querySelectorAll('.portfolio');
        const width2 = [1, 2, 5, 6];
        width2.map(item => {
            portfolioItems[item - 1] ? portfolioItems[item - 1].classList.add('grid-width-2') : console.log("error");
            return 0;
        })
        const grid = document.querySelector('.masonry-list');
        if (grid != null) {
            imagesLoaded(grid, () => {
                new Masonry(grid);
            });
        }


    return (
        <div className="mt--100 mt_sm--25">
            <div className="portfolio-grid masonry-list">
                <div className="resizer" />
                {
                    projects ? projects.map((project, index) => (
                        <PortfolioItem
                            key={index}
                            index={index + 1}
                            title={project.title}
                            tags={project.tags}
                            thumb={`${process.env.PUBLIC_URL}/projects/${projects[index].id}/cover.jpg`}
                        />
                    )).reverse() : ""
                }
            </div>
        </div>
    );
};

export default ProjectsShowcase;
