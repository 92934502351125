import React from 'react';
import { NavLink } from 'react-router-dom';
import menuItem from "../../data/navbar"

const NavbarItem = () => {

    const closeMobileMenu = () => {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.remove('popup-mobile-menu-wrapper');
        html.style.overflow = "auto"
    };

    return (
        menuItem.map(menuItem => (
            <li key={menuItem.id}>
            <NavLink exact={menuItem.exact} to={`${process.env.PUBLIC_URL + menuItem.link}`} activeClassName="navlink-active" onClick={() => { closeMobileMenu() }}>
                <span>{menuItem.title}</span>
            </NavLink>
        </li>
        ))
    );
};

export default NavbarItem;