import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import LayoutDefault from "../container/LayoutDefault";


const Cookies = () => {

    const goToPreviousPage = () => {
        if (window.history.length === 1) {
            window.location = "/"
        } else {
            window.history.back();
        }
    }

    return (
        <>
            <Helmet>
                <title>TheBrandBar | Cookies</title>
            </Helmet>
            <LayoutDefault className="template-color-2 bg_color--4">
                <button onClick={goToPreviousPage} className="page-close" />
                <Container>
                    <Row>
                        <Col className="tpc-text">
                            <br /><br />
                            <h2>COOKIES</h2>
                            <br /><br />
                            <p>
                                <span>1.Cookies</span>
                                <br />
                                To make this site work properly, we sometimes place small data
                                files called cookies on your device. Most big websites do this too.
                                <br /><br />
                                <span>2.1 What is a cookie?</span>
                                <br />
                                A cookie is a block of data that is sent by the server on which a
                                website is hosted to a browser, and is also stored by that browser.
                                This block of data (text or numbers) is sent each time a user accesses a page.
                                <br /><br />
                                <span>2.2. How long does a cookie last?</span>
                                <br />
                                Cookies fall into two categories depending on their lifetime.
                                Thus, we have the following two categories:
                                <br />
                                persistent cookies: a persistent cookie will be stored by a web browser and will remain valid until the date set to expire, unless it is deleted by the user before this date.
                                <br />
                                session cookies: these are cookies specific to a browsing session and will expire when the user closes the browser in which they are browsing.
                                <br /><br />
                                <span>2.3 What information do cookies contain?</span>
                                <br />
                                Cookies do not normally contain personal data that personally identifies the user, but the information we store about you may be linked to the information we obtain through cookies.
                                <br /><br />
                                <span>2.4 How are cookies used?</span>
                                <br />
                                Cookies can be used by the website server to identify the pages they visit and to identify those who return to our platform.
                                <br />
                                The cookie-related information is not used to identify you personally and the pattern data is fully under our control. These cookies are not used for any purpose other than those described here.
                                <br /><br />
                                <span>3. How to control cookies?</span>
                                <br />
                                You can control and/or delete cookies as you wish - for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
                                <br /><br />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </LayoutDefault>
        </>
    );
};

export default Cookies;
