import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Cookies from "../components/cookies";
import LayoutDefault from "../container/LayoutDefault";

const Terms = () => {
    const goToPreviousPage = () => {
        if (window.history.length === 1) {
            window.location = "/"
        } else {
            window.history.back();
        }
    }
    return (
        <>
            <Helmet>
                <title>TheBrandBar | Terms of Use</title>
            </Helmet>
            <Cookies />
            <LayoutDefault className="template-color-2 bg_color--4">
                <button onClick={goToPreviousPage} className="page-close" />
                <Container>
                    <Row>
                        <Col className="tpc-text">
                            <br /><br />
                            <h2>TERMS OF USE</h2>
                            <br /><br />
                            <p>
                                <span>1.General conditions for using the site</span>
                                <br />
                                The main role of the site www.thebrandbar.studio is to inform all
                                users about what our company does and to showcase design projects.
                                Your use of the site is subject to compliance with these general
                                terms and conditions of access and use, detailed below.
                                At the same time, accessing the site means implicit acceptance of
                                the general conditions of its use.
                                <br /><br />
                                <span>2. Intelectual property</span>
                                <br />
                                This website is owned and managed by the The Brand Bar.
                                Its contents, meaning the existing images and texts,
                                are the property of the The Brand Bar.
                                Reproduction, in both physical and digital format, of images,
                                texts on the Site or excerpts from such texts is permitted provided
                                that they are used for strictly personal purposes,
                                excluding use for advertising or commercial purposes,
                                so as to comply with the legislation in force regarding intellectual
                                property. Any other use or partial modification of them is strictly
                                prohibited and is considered a crime, punishable under applicable law.
                                <br /><br />
                                <span>3.Changing the terms and conditions</span>
                                <br />
                                The Brand Bar reserves the right to change and update at any time
                                the content of this page, but also of the current website, without
                                any prior notice. If you have any questions about the general conditions
                                of use, please contact us at the following email address:
                                cheers@thebrandbar.studio .
                            </p>
                        </Col>
                    </Row>
                </Container>
            </LayoutDefault>
        </>
    );
};

export default Terms;
