import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import ale_mare from '../../assets/img/team/ale_mare.jpg';
import ale_mica from '../../assets/img/team/ale_mica.jpg';
import andre from '../../assets/img/team/andre.jpg';
import hand from '../../assets/img/shapes/hand.svg'


const TeamMemberGrid = () => {
    return (
        <div className="mb--50 mt--100 mt_lg--0">
            <Container>
                <Row>
                    <Col sm={6} lg={4} className="mt--60 ale_mare">
                        <div className="team">
                            <div className="thumb">
                                <img className="member-img" src={ale_mare} alt="" />
                                <div className="overlay">
                                    <div className="info">
                                        <img src={hand} alt="" />
                                        <h4>ALEXANDRA SINTAMARIAN</h4>
                                        <p>Branding Ninja and Detail Perfectionist</p>
                                        <p>Passionate about UI & shaormas. Likes things to be perfect, obviously.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col sm={6} lg={4} className="mt--60 ale_mica">
                        <div className="team">
                            <div className="thumb">
                                <img className="member-img" src={ale_mica} alt="" />
                                <div className="overlay">
                                    <div className="info">
                                        <img src={hand} alt="" />
                                        <h4>ALEXANDRA CHERECHES</h4>
                                        <p>Visual Fireworks Specialist and Patience Jedi</p>
                                        <p>Loves cooking und spircht Deutsch. Disciplined and likes to organise things. We wonder why..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col sm={6} lg={4} className="mt--60 andre">
                        <div className="team">
                            <div className="thumb">
                                <img className="member-img" src={andre} alt="" />
                                <div className="overlay">
                                    <div className="info">
                                        <img src={hand} alt="" />
                                        <h4>ANDREEA BLAG</h4>
                                        <p>User Wizard and Creative Overthinker</p>
                                        <p>*Big Harry Potter fan (*biggest). Ambitious and resourceful, like a true Slytherin.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
};

export default TeamMemberGrid;
