import React, { useState, useEffect } from 'react';
import Loader from "../container/Loader";
import { Helmet } from "react-helmet";
import Cookies from "../components/cookies";
import LayoutDefault from "../container/LayoutDefault";
import Header from "../components/header/Header";
import HeroArea from "../container/Hero";
import MusicPlayer from "../container/MusicPlayer";
import Services from "../container/Services";
import FeaturedProjects from "../container/FeaturedProjects";
import Contact from "../container/Contact";
import Footer from "../container/Footer";

const Home = ({ parentCallback, player, musicCover }) => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
    const [trackURL, setTrackURL] = useState('');
    const [playing, setPlaying] = useState(false);

    const callbackFunction = (currentTrackIndex, trackURL, playing) => {
        setCurrentTrackIndex(currentTrackIndex);
        setTrackURL(trackURL);
        setPlaying(playing);
    };

    useEffect(() => {
        parentCallback(currentTrackIndex, trackURL, playing);
    }, [currentTrackIndex, trackURL, playing]);


    return (
        <Loader>
            <Helmet>
                <title>TheBrandBar</title>
            </Helmet>
            <Cookies />
            <LayoutDefault className="template-color-2 bg_color--4">
                <Header />
                <HeroArea />
                <MusicPlayer parentCallback={callbackFunction} player={player} musicCover={musicCover}/>
                <Services />
                <FeaturedProjects />
                <Contact />
                <Footer />
            </LayoutDefault>
        </Loader>
    );
};

export default Home;