import React from 'react';
import { NavLink } from 'react-router-dom';
import navbarData from "../../../../data/navbar"

const NavbarItem = () => {
    return (
        navbarData.map(navbar => (
            <li key={navbar.id} className="label-1">
                <NavLink exact={navbar.exact} to={`${process.env.PUBLIC_URL + navbar.link}`} activeClassName="navlink-active"><span>{navbar.title}</span></NavLink>
            </li>
        ))
    );
};

export default NavbarItem;